export enum DataType {
  Button = 'button',
  Config = 'config',
  Image = 'image',
  Page = 'page',
  BlogPostPage = 'blog-post-page',
  RealEstateDetailsPage = 'real-estate-details-page',
  PageSeo = 'page-seo',
  HeroSection = 'hero-section',
  CtaSection = 'cta-section',
  CarouselSection = 'carousel-section',
  NumbersSection = 'numbers-section',
  TestimonialsSection = 'testimonials-section',
  PartnersSection = 'partners-section',
  TilesSection = 'tiles-section',
  SimpleSection = 'simple-section',
  BlogSection = 'blog-section',
  FormField = 'form-field',
  Form = 'form',
  AboutSection = 'about-section',
  AboutSectionItem = 'about-section-item',
  BlogSummarySection = 'blog-summary-section',
  RealEstatesSummarySection = 'real-estates-summary-section',
  FormSection = 'form-section',
  RealEstatesSection = 'real-estates-section',
  RealEstateType = 'real-estate-type',
  InvestmentsReferencesSection = 'investments-references-section',
  EmbeddedMapSection = 'embedded-map-section',
}

export type DataEntryBase<TType extends DataType, TData extends unknown> = {
  id: string;
  type: TType;
  data: TData;
};
