import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, EmbeddedMapSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<EmbeddedMapSectionData> => {
  const list: EmbeddedMapSectionData[] = graphqlResult.allContentfulEmbeddedMapSection.nodes.map(
    (node): EmbeddedMapSectionData => ({
      id: node.contentful_id,
      type: DataType.EmbeddedMapSection,
      data: rejectNil({
        location: node.location,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useEmbeddedMapSectionData: DataHook<EmbeddedMapSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulEmbeddedMapSection {
        nodes {
          contentful_id
          location {
            lon
            lat
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
