import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, RealEstatesSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';

const makeIndex = (graphqlResult: any): DataIndex<RealEstatesSectionData> => {
  const list: RealEstatesSectionData[] = graphqlResult.allContentfulRealEstatesSection.nodes.map(
    (node): RealEstatesSectionData => ({
      id: node.contentful_id,
      type: DataType.RealEstatesSection,
      data: rejectNil({
        title: node.title,
        description: node.description,
        pageSize: node.pageSize,
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useRealEstatesSectionData: DataHook<RealEstatesSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulRealEstatesSection {
        nodes {
          contentful_id
          title
          description {
            raw
          }
          pageSize
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult);

  return id === undefined ? index : index.record[id];
};
