import { DataEntryBase, DataType } from './common';
import { ImageData } from './image';

export enum FormFieldType {
  Email = 'email',
  Number = 'number',
  Text = 'text',
  Textarea = 'textarea',
  PhoneNumber = 'phoneNumber',
  Slider = 'slider',
  Checkbox = 'checkbox',
  Radio = 'radio',
}

export interface FormFieldOption {
  label: string;
  value: string;
  icon: ImageData;
}

export type FormFieldData = DataEntryBase<
  DataType.FormField,
  {
    label?: string;
    name: string;
    type: FormFieldType;
    validationMessage?: string;
    helpMessage?: string;
    placeholder?: string;
    required?: boolean;
    min?: number;
    max?: number;
    unit?: string;
    options?: FormFieldOption[];
  }
>;
