import { graphql, useStaticQuery } from 'gatsby';

import { DataType, RealEstateDetailsPartial } from '../model';

const makeList = (graphqlResult): RealEstateDetailsPartial[] => {
  const list = graphqlResult.allContentfulRealEstateDetailsPage.nodes.map(
    (node): RealEstateDetailsPartial => ({
      seo: {
        id: node.seo.contentful_id,
        type: DataType.PageSeo,
        data: node.seo,
      },
      image: {
        id: node.image.contentful_id,
        type: DataType.Image,
        data: undefined,
      },
      date: node.date,
      state: node.state,
      description: node.description,
      slug: node.slug,
      title: node.title,
      type: {
        id: node.type.contentful_id,
        type: DataType.RealEstateType,
        data: node.type,
      },
      size: node.size,
      price: node.price,
      finalEnergyConsumption: node.finalEnergyConsumption,
      primaryEnergyConsumption: node.primaryEnergyConsumption,
      location: node.location,
      formattedLocation: node.formattedLocation,
    }),
  );

  return list;
};

export const useRealEstateDetailsPartialsData = (): RealEstateDetailsPartial[] => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulRealEstateDetailsPage(sort: { order: DESC, fields: date }) {
        nodes {
          contentful_id
          slug
          seo {
            contentful_id
          }
          date
          title
          state
          type {
            contentful_id
            name
            icon {
              contentful_id
            }
          }
          image {
            contentful_id
          }
          size
          price
          description {
            raw
          }
          finalEnergyConsumption
          primaryEnergyConsumption
          location {
            lon
            lat
          }
          formattedLocation
        }
      }
    }
  `);

  return makeList(graphqlResult);
};
