import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import {
  DataType,
  FormData,
  FormSectionData,
  TilesSectionData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useFormData } from './use-form-data';
import { useTilesSectionData } from './use-tiles-section-data';

const makeIndex = (
  graphqlResult: any,
  formDataIndex: DataIndex<FormData>,
  tilesSectionDataIndex: DataIndex<TilesSectionData>,
): DataIndex<FormSectionData> => {
  const list: FormSectionData[] = graphqlResult.allContentfulFormSection.nodes.map(
    (node): FormSectionData => ({
      id: node.contentful_id,
      type: DataType.FormSection,
      data: rejectNil({
        title: node.title,
        description: node.description,
        content: node.content,
        form: formDataIndex?.record?.[node.form?.contentful_id],
        tiles: tilesSectionDataIndex?.record?.[node.tiles?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useFormSectionData: DataHook<FormSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulFormSection {
        nodes {
          contentful_id
          title
          description {
            raw
            references {
              contentful_id
              title
              file {
                url
              }
            }
          }
          form {
            contentful_id
          }
          content {
            raw
          }
          tiles {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useFormData(), useTilesSectionData());

  return id === undefined ? index : index.record[id];
};
