import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { ConfigData, DataType, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeLinks = data =>
  (data ?? []).map(item => ({
    ...item,
    link: item.link?.pageId,
    subRoutes: item.subRoutes?.map(subRoute => ({
      ...subRoute,
      link: subRoute.link?.pageId,
    })),
  }));

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<ConfigData> => {
  const list: ConfigData[] = graphqlResult.allContentfulConfig.nodes.map(
    (node): ConfigData => ({
      id: node.contentful_id,
      type: DataType.Config,
      data: rejectNil({
        companyName: node.companyName,
        currency: node.currency,
        email: node.email,
        cookiesContent: node.cookiesContent,
        cookiesButtonLabel: node.cookiesButtonLabel,
        address: node.address,
        formActionUrl: node.formActionUrl,
        logo: imageDataIndex.record?.[node.logo?.contentful_id],
        translations: node.translations,
        phoneNumber: node.phoneNumber,
        socialMedias: node.socialMedias,
        theme: node.theme,
        mainNavigation: makeLinks(node.mainNavigation),
        footerNavigation: makeLinks(node.footerNavigation),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useConfigData: DataHook<ConfigData> = (): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulConfig {
        nodes {
          email
          phoneNumber
          currency
          companyName
          formActionUrl
          cookiesContent {
            raw
          }
          cookiesButtonLabel
          address {
            raw
          }
          logo {
            contentful_id
          }
          theme {
            fontFamilies
            primaryColor
            onPrimaryColor
            secondaryColor
            onSecondaryColor
            textColor
          }
          socialMedias {
            name
            url
          }
          mainNavigation {
            title
            link {
              pageId
            }
            subRoutes {
              title
              link {
                pageId
              }
            }
          }
          footerNavigation {
            title
            link {
              pageId
            }
            subRoutes {
              title
              link {
                pageId
              }
            }
          }
          translations {
            formSuccessMessage
            formErrorMessage
            captchaErrorMessage
            priceRequest
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return index;
};
