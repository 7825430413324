import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, FormData, FormFieldData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useFormFieldData } from './use-form-field-data';

const makeIndex = (
  graphqlResult: any,
  itemsDataIndex: DataIndex<FormFieldData>,
): DataIndex<FormData> => {
  const list: FormData[] = graphqlResult.allContentfulForm.nodes.map(
    (node): FormData => ({
      id: node.contentful_id,
      type: DataType.Form,
      data: rejectNil({
        fields: node.formFields?.map(
          field => itemsDataIndex.record[field.contentful_id],
        ),
        fieldsGroups: node.formFieldsGroups?.map(group => ({
          title: group?.title,
          fields: group?.formFields?.map(
            field => itemsDataIndex.record[field.contentful_id],
          ),
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useFormData: DataHook<FormData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulForm {
        nodes {
          contentful_id
          formFields {
            contentful_id
          }
          formFieldsGroups {
            title
            formFields {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useFormFieldData());

  return id === undefined ? index : index.record[id];
};
