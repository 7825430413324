import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, FormFieldData, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<FormFieldData> => {
  const list: FormFieldData[] = graphqlResult.allContentfulFormField.nodes.map(
    (node): FormFieldData => ({
      id: node.contentful_id,
      type: DataType.FormField,
      data: rejectNil({
        label: node.label,
        name: node.name,
        type: node.type,
        validationMessage: node.validationMessage,
        helpMessage: node.helpMessage,
        required: node.required,
        min: node.min,
        max: node.max,
        unit: node.unit,
        options: node?.options?.map(option => ({
          ...option,
          icon: imageDataIndex?.record[option?.icon?.contentful_id],
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useFormFieldData: DataHook<FormFieldData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulFormField {
        nodes {
          contentful_id
          label
          name
          type
          required
          min
          max
          unit
          options {
            label
            value
            icon {
              contentful_id
            }
          }
          # TODO when issue related to contentful schema are solved, uncomment
          # placeholder
          validationMessage
          # helpMessage
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
