import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, ImageData, RealEstateTypeData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<RealEstateTypeData> => {
  const list: RealEstateTypeData[] = graphqlResult.allContentfulRealEstateType.nodes.map(
    (node): RealEstateTypeData => ({
      id: node.contentful_id,
      type: DataType.RealEstateType,
      data: rejectNil({
        name: node.name,
        icon: imageDataIndex?.record?.[node.icon?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};
export const useRealEstateTypeData: DataHook<RealEstateTypeData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulRealEstateType(sort: { fields: createdAt, order: ASC }) {
        nodes {
          contentful_id
          createdAt
          name
          icon {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
