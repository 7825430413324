import { ContentfulRichText } from '../../types';
import { DataEntryBase, DataType } from './common';
import { ImageData } from './image';

// uniq id for the page, should match 1:1 values from CMS Config model
export enum StaticPageId {
  Home = 'Home',
  Contact = 'Kontakt',
  About = 'UberAus',
  Tippgeberpramie = 'Tippgeberprämie',
  FindRealEstate = 'WunschimmobilieFinden',
  SellRealEstates = 'ImmobilieVerkaufen',
  Investment = 'Investment',
  RealEstates = 'ImmobilienAngebote',
  Blog = 'Blog',
  PrivacyPolicy = 'Datenschutzerklärung',
  Imprint = 'Impressum',
}

// uniq template type for the page generation, should match 1:1 values from CMS Config model
export enum TemplateType {
  Page = 'page',
  BlogPost = 'blog-post',
  RealEstateDetails = 'real-estate-details',
}

export interface NavigationConfig {
  link: StaticPageId;
  title: string;
  subRoutes?: NavigationConfig[];
}

export interface SocialMediaConfig {
  name: string;
  url: string;
}

export interface ThemeConfig {
  fontFamilies: string[];
  primaryColor: string;
  onPrimaryColor: string;
  secondaryColor: string;
  onSecondaryColor: string;
  textColor: string;
}

export type ConfigData = DataEntryBase<
  DataType.Config,
  {
    address: ContentfulRichText;
    companyName: string;
    cookiesContent: ContentfulRichText;
    cookiesButtonLabel: string;
    currency: string;
    email: string;
    footerNavigation: NavigationConfig[];
    formActionUrl: string;
    logo: ImageData;
    mainNavigation: NavigationConfig[];
    phoneNumber: string;
    socialMedias: SocialMediaConfig[];
    theme: ThemeConfig;
    translations?: {
      captchaErrorMessage?: string;
      formErrorMessage?: string;
      formSuccessMessage?: string;
      mediaTitle?: string;
      owner?: string;
      priceRequest?: string;
    };
  }
>;
