import './styles/container.scss';

import React, { CSSProperties, memo } from 'react';

import { joinClassNames } from '../../utils/tsx';
import useScreens from './hooks/useScreens';
import { BreakpointProperty, LayoutComponent } from './types';
import {
  makeClassName,
  makeClassNamesForBreakpoints,
  makeHiddenClassNameForBreakpoint,
  resolveBreakpointPropertyValue,
} from './utils';

export interface ContainerProps {
  className?: string;
  fullWidth?: BreakpointProperty<boolean>;
  style?: BreakpointProperty<CSSProperties>;
  fullHeight?: BreakpointProperty<boolean>;
  /** Whether the container should be hidden (equivalent of CSS "display: none") */
  hidden?: BreakpointProperty<boolean>;
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  fullWidth,
  fullHeight,
  style,
  hidden,
}) => {
  const [screens] = useScreens();

  const breakpoint = screens?.[0];

  const breakpointStyle = resolveBreakpointPropertyValue(breakpoint, style);

  return (
    <div
      className={joinClassNames(
        makeClassName(LayoutComponent.Container),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Container,
          'full-width',
          fullWidth,
        ),
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Container,
          'full-height',
          fullHeight,
        ),
        makeHiddenClassNameForBreakpoint(
          LayoutComponent.Container,
          hidden,
          breakpoint,
        ),
        className,
      )}
      style={breakpointStyle}
    >
      {children}
    </div>
  );
};

export default memo(Container);
