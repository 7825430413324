import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { AboutSectionData, AboutSectionItemData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useAboutSectionItemData } from './use-about-section-item-data';

const makeIndex = (
  graphqlResult: any,
  itemsDataIndex: DataIndex<AboutSectionItemData>,
): DataIndex<AboutSectionData> => {
  const list: AboutSectionData[] = graphqlResult.allContentfulAboutSection.nodes.map(
    (node): AboutSectionData => ({
      id: node.contentful_id,
      type: DataType.AboutSection,
      data: rejectNil({
        title: node.title,
        description: node.description,
        items: node.items.map(
          item => itemsDataIndex.record[item.contentful_id],
        ),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useAboutSectionData: DataHook<AboutSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulAboutSection {
        nodes {
          contentful_id
          title
          description {
            raw
          }
          items {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useAboutSectionItemData());

  return id === undefined ? index : index.record[id];
};
