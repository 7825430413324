import { AboutSectionData } from './about-section';
import { BlogSectionData } from './blog-section';
import { BlogSummarySectionData } from './blog-summary-section';
// import { CarouselSectionData } from './carousel-section';
import { DataEntryBase, DataType } from './common';
import { StaticPageId } from './config';
import { CtaSectionData } from './cta-section';
import { EmbeddedMapSectionData } from './embedded-map-section';
import { FormSectionData } from './form-section';
import { HeroSectionData } from './hero-section';
import { InvestmentsReferencesSectionData } from './investments-references-section';
import { NumbersSectionData } from './numbers-section';
import { PageSeoData } from './page-seo';
import { PartnersSectionData } from './partners-section';
import { RealEstatesSectionData } from './real-estates-section';
import { RealEstatesSummarySectionData } from './real-estates-summary-section';
import { SimpleSectionData } from './simple-section';
import { TestimonialsSectionData } from './testimonials-section';
import { TilesSectionData } from './tiles-section';

export type PageSectionsData =
  | AboutSectionData
  | BlogSectionData
  | BlogSummarySectionData
  // | CarouselSectionData
  | CtaSectionData
  | FormSectionData
  | HeroSectionData
  | NumbersSectionData
  | PartnersSectionData
  | RealEstatesSectionData
  | RealEstatesSummarySectionData
  | SimpleSectionData
  | TestimonialsSectionData
  | InvestmentsReferencesSectionData
  | EmbeddedMapSectionData
  | TilesSectionData;

export enum PageThemeMode {
  Dark = 'dark',
  Light = 'light',
}

export interface PageTheme {
  navigationMode?: PageThemeMode;
  backgroundMode?: PageThemeMode;
}

export type PageData = DataEntryBase<
  DataType.Page,
  {
    seo: PageSeoData;
    slug: string;
    pageId: StaticPageId;
    pageTheme: PageTheme;
    sections: PageSectionsData[];
  }
>;
