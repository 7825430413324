import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, PageData, PageSectionsData, PageSeoData } from '../model';
import {
  CombinedIndex,
  DataHook,
  DataIndex,
  RequiredDataHooks,
} from '../types';
import { makeDataIndexFromList } from '../utils';
import { useAboutSectionData } from './use-about-section-data';
import { useBlogSectionData } from './use-blog-section-data';
import { useBlogSummarySectionData } from './use-blog-summary-section-data';
// import { useCarouselSectionData } from './use-carousel-section-data';
import { useCtaSectionData } from './use-cta-section-data';
import { useEmbeddedMapSectionData } from './use-embedded-map-section';
import { useFormSectionData } from './use-form-section-data';
import { useHeroSectionData } from './use-hero-section-data';
import { useInvestmentsReferencesSectionData } from './use-investments-references-section-data';
import { useNumbersSectionData } from './use-numbers-section-data';
import { usePageSeoData } from './use-page-seo-data';
import { usePartnersSectionData } from './use-partners-section-data';
import { useRealEstatesSectionData } from './use-real-estates-section-data';
import { useRealEstatesSummarySectionData } from './use-real-estates-summary-section-data';
import { useSimpleSectionData } from './use-simple-section-data';
import { useTestimonialsSectionData } from './use-testimonials-section-data';
import { useTilesSectionData } from './use-tiles-section-data';

const requiredDataHooks: RequiredDataHooks<PageSectionsData> = {
  [DataType.HeroSection]: useHeroSectionData,
  [DataType.CtaSection]: useCtaSectionData,
  // [DataType.CarouselSection]: useCarouselSectionData,
  [DataType.NumbersSection]: useNumbersSectionData,
  [DataType.TestimonialsSection]: useTestimonialsSectionData,
  [DataType.TilesSection]: useTilesSectionData,
  [DataType.PartnersSection]: usePartnersSectionData,
  [DataType.SimpleSection]: useSimpleSectionData,
  [DataType.BlogSection]: useBlogSectionData,
  [DataType.AboutSection]: useAboutSectionData,
  [DataType.BlogSummarySection]: useBlogSummarySectionData,
  [DataType.RealEstatesSummarySection]: useRealEstatesSummarySectionData,
  [DataType.FormSection]: useFormSectionData,
  [DataType.RealEstatesSection]: useRealEstatesSectionData,
  [DataType.InvestmentsReferencesSection]: useInvestmentsReferencesSectionData,
  [DataType.EmbeddedMapSection]: useEmbeddedMapSectionData,
};

const makeIndex = (
  graphqlResult: any,
  pageSeoDataIndex: DataIndex<PageSeoData>,
  sectionsDataIndex: CombinedIndex<PageSectionsData>,
): DataIndex<PageData> => {
  const list: PageData[] = graphqlResult.allContentfulPage.nodes.map(
    (node): PageData => ({
      id: node.contentful_id,
      type: DataType.Page,
      data: rejectNil({
        seo: pageSeoDataIndex.record[node.seo.contentful_id],
        pageId: node.pageId.pageId,
        slug: node.slug,
        pageTheme: node.pageTheme,
        sections:
          node.sections?.map(
            section => sectionsDataIndex[section.contentful_id],
          ) ?? [],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const usePageData: DataHook<PageData> = (id?: string): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulPage {
        nodes {
          contentful_id
          pageId {
            pageId
          }
          slug
          seo {
            contentful_id
          }
          pageTheme {
            navigationMode
            backgroundMode
          }
          sections {
            ... on ContentfulHeroSection {
              contentful_id
            }
            ... on ContentfulCtaSection {
              contentful_id
            }
            # ... on ContentfulCarouselSection {
            #   contentful_id
            # }
            ... on ContentfulNumbersSection {
              contentful_id
            }
            ... on ContentfulTestimonialsSection {
              contentful_id
            }
            ... on ContentfulTilesSection {
              contentful_id
            }
            ... on ContentfulPartnersSection {
              contentful_id
            }
            ... on ContentfulSimpleSection {
              contentful_id
            }
            ... on ContentfulBlogSection {
              contentful_id
            }
            ... on ContentfulAboutSection {
              contentful_id
            }
            ... on ContentfulBlogSummarySection {
              contentful_id
            }
            ... on ContentfulRealEstatesSummarySection {
              contentful_id
            }
            ... on ContentfulFormSection {
              contentful_id
            }
            ... on ContentfulRealEstatesSection {
              contentful_id
            }
            ... on ContentfulInvestmentsReferencesSection {
              contentful_id
            }
            ... on ContentfulEmbeddedMapSection {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const sectionsDataIndex = Object.values(requiredDataHooks).reduce<
    CombinedIndex<PageSectionsData>
  >((acc, dataHook) => ({ ...acc, ...dataHook()?.record }), {});

  const index = makeIndex(graphqlResult, usePageSeoData(), sectionsDataIndex);

  return id === undefined ? index : index.record[id];
};
