import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { ButtonData, DataType, ImageData, TilesSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useButtonData } from './use-button-data';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
  buttonDataIndex: DataIndex<ButtonData>,
): DataIndex<TilesSectionData> => {
  const list: TilesSectionData[] = graphqlResult.allContentfulTilesSection.nodes.map(
    (node): TilesSectionData => ({
      id: node.contentful_id,
      type: DataType.TilesSection,
      data: rejectNil({
        title: node.title,
        description: node.description,
        showItemsCount: node.showItemsCount,
        items: node.items.map(item => ({
          ...item,
          icon: imageDataIndex?.record?.[item.icon?.contentful_id],
          button: buttonDataIndex?.record?.[item.button?.contentful_id],
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useTilesSectionData: DataHook<TilesSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulTilesSection {
        nodes {
          contentful_id
          title
          description {
            raw
          }
          showItemsCount
          items {
            title
            description {
              raw
            }
            button {
              contentful_id
            }
            icon {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData(), useButtonData());

  return id === undefined ? index : index.record[id];
};
