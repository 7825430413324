import {
  BlogPostPartial,
  PageData,
  RealEstateDetailsPartial,
  StaticPageId,
} from '../data';

export const resolveBlogPostPagePath = (
  pagesConfig: PageData[],
  blogPartial: BlogPostPartial,
): string => {
  // StaticPageId.Blog is a blog by the definition of this website, optimize computation now each call invokes find operation
  const {
    data: { slug },
  } = pagesConfig.find(page => page.data.pageId === StaticPageId.Blog);
  return `${slug}${blogPartial.slug}`;
};

export const resolveRealEstateDetailsPagePath = (
  pagesConfig: PageData[],
  realEstateDetailsPartial: RealEstateDetailsPartial,
): string => {
  // StaticPageId.RealEstate is a real estates by the definition of this website, optimize computation now each call invokes find operation
  const {
    data: { slug },
  } = pagesConfig.find(page => page.data.pageId === StaticPageId.RealEstates);

  return `${slug}${realEstateDetailsPartial.slug}`;
};
