import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import {
  DataType,
  ImageData,
  InvestmentsReferencesSectionData,
} from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<InvestmentsReferencesSectionData> => {
  const list: InvestmentsReferencesSectionData[] = graphqlResult.allContentfulInvestmentsReferencesSection.nodes.map(
    (node): InvestmentsReferencesSectionData => ({
      id: node.contentful_id,
      type: DataType.InvestmentsReferencesSection,
      data: rejectNil({
        title: node.title,
        items: node.items.map(item => ({
          ...item,
          image: imageDataIndex?.record?.[item.image?.contentful_id],
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useInvestmentsReferencesSectionData: DataHook<InvestmentsReferencesSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulInvestmentsReferencesSection {
        nodes {
          contentful_id
          title
          items {
            state
            title {
              raw
            }
            description {
              raw
            }
            image {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
