export const computeCurrency = (value: number, currency: string): string => {
  const currencies = {
    EUR: 'de-DE',
    PLN: 'pl-PL',
  };

  const formattedCurrency = currency.toUpperCase();

  const languageKey = currencies[formattedCurrency];

  return new Intl.NumberFormat(languageKey, {
    style: 'currency',
    currency: formattedCurrency,
  }).format(value);
};
