import './styles/section.scss';

import React, { CSSProperties, memo } from 'react';

import { joinClassNames } from '../../utils/tsx';
import useScreens from './hooks/useScreens';
import { BreakpointProperty, LayoutComponent } from './types';
import {
  makeClassName,
  makeClassNamesForBreakpoints,
  makeHiddenClassNameForBreakpoint,
  resolveBreakpointPropertyValue,
} from './utils';

export interface SectionProps {
  className?: string;
  style?: BreakpointProperty<CSSProperties>;
  /** Whether the section's minimum height should be equal to viewport height */
  viewportHeight?: BreakpointProperty<boolean>;
  /** Whether the section should be hidden (equivalent of CSS "display: none") */
  hidden?: BreakpointProperty<boolean>;
  /** Whether the section should not apply default vertical padding */
  noPadding?: boolean;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
  className,
  children,
  style,
  viewportHeight,
  hidden,
  noPadding = false,
}) => {
  const [screens] = useScreens();

  const breakpoint = screens?.[0];

  const breakpointStyle = resolveBreakpointPropertyValue(breakpoint, style);

  const sectionMainClassName = makeClassName(LayoutComponent.Section);

  return (
    <section
      className={joinClassNames(
        sectionMainClassName,
        ...makeClassNamesForBreakpoints(
          LayoutComponent.Section,
          'viewport-height',
          viewportHeight,
        ),
        makeHiddenClassNameForBreakpoint(
          LayoutComponent.Section,
          hidden,
          breakpoint,
        ),
        className,
        !noPadding && `${sectionMainClassName}-padding`,
      )}
      style={breakpointStyle}
    >
      {children}
    </section>
  );
};

export default memo(Section);
