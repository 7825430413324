export const debounce = <TFunction extends (...args: any[]) => void>(
  delay: number,
  fn: TFunction,
) => {
  let timeoutID: number | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((...args: any[]) => {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn(...args), delay);
  }) as TFunction;
};
