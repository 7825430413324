export const joinDefined = (separator: string, parts: unknown[]) =>
  parts
    .filter(element => element !== undefined && element !== null)
    .join(separator);

export const chunk = (input, size) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};
