import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, HeroSectionData, ImageData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<HeroSectionData> => {
  const list: HeroSectionData[] = graphqlResult.allContentfulHeroSection.nodes.map(
    (node): HeroSectionData => ({
      id: node.contentful_id,
      type: DataType.HeroSection,
      data: rejectNil({
        carouselItems: node.carouselItems?.map(carouselItem => ({
          ...carouselItem,
          desktopImage:
            imageDataIndex?.record?.[carouselItem.desktopImage?.contentful_id],
          mobileImage:
            imageDataIndex?.record?.[carouselItem.mobileImage?.contentful_id],
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useHeroSectionData: DataHook<HeroSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulHeroSection {
        nodes {
          contentful_id
          carouselItems {
            title {
              raw
            }
            desktopImage {
              contentful_id
            }
            mobileImage {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
