import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { DataType, ImageData, NumbersSectionData } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useImageData } from './use-image-data';

const makeIndex = (
  graphqlResult: any,
  imageDataIndex: DataIndex<ImageData>,
): DataIndex<NumbersSectionData> => {
  const list: NumbersSectionData[] = graphqlResult.allContentfulNumbersSection.nodes.map(
    (node): NumbersSectionData => ({
      id: node.contentful_id,
      type: DataType.NumbersSection,
      data: rejectNil({
        title: node.title,
        items: node.items.map(item => ({
          ...item,
          icon: imageDataIndex?.record?.[item.icon?.contentful_id],
        })),
      }),
    }),
  );

  return makeDataIndexFromList(list);
};
export const useNumbersSectionData: DataHook<NumbersSectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulNumbersSection {
        nodes {
          contentful_id
          title
          items {
            title
            count
            beforeUnit
            afterUnit
            icon {
              contentful_id
            }
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useImageData());

  return id === undefined ? index : index.record[id];
};
