import { graphql, useStaticQuery } from 'gatsby';

import { rejectNil } from '../../utils';
import { BlogSummarySectionData, ButtonData, DataType } from '../model';
import { DataHook, DataIndex } from '../types';
import { makeDataIndexFromList } from '../utils';
import { useButtonData } from './use-button-data';

const makeIndex = (
  graphqlResult: any,
  buttonDataIndex: DataIndex<ButtonData>,
): DataIndex<BlogSummarySectionData> => {
  const list: BlogSummarySectionData[] = graphqlResult.allContentfulBlogSummarySection.nodes.map(
    (node): BlogSummarySectionData => ({
      id: node.contentful_id,
      type: DataType.BlogSummarySection,
      data: rejectNil({
        title: node.title,
        itemsCount: node.itemsCount,
        button: buttonDataIndex?.record?.[node.button?.contentful_id],
      }),
    }),
  );

  return makeDataIndexFromList(list);
};

export const useBlogSummarySectionData: DataHook<BlogSummarySectionData> = (
  id?: string,
): any => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulBlogSummarySection {
        nodes {
          contentful_id
          title
          itemsCount
          button {
            contentful_id
          }
        }
      }
    }
  `);

  const index = makeIndex(graphqlResult, useButtonData());

  return id === undefined ? index : index.record[id];
};
