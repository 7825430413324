interface Task {
  loading: boolean;
  error?: Error;
}

export const isTaskLoaded = (currentTask: Task, previousTask: Task): boolean =>
  previousTask.loading === true && currentTask.loading === false;

export const isTaskResolved = (
  currentTask: Task,
  previousTask: Task,
): boolean =>
  isTaskLoaded(currentTask, previousTask) && currentTask.error === undefined;

export const isTaskRejected = (
  currentTask: Task,
  previousTask: Task,
): boolean =>
  isTaskLoaded(currentTask, previousTask) && currentTask.error !== undefined;
