import { graphql, useStaticQuery } from 'gatsby';

import { BlogPostPartial, DataType } from '../model';

const makeList = (graphqlResult): BlogPostPartial[] => {
  const list = graphqlResult.allContentfulBlogPostPage.nodes.map(
    (node): BlogPostPartial => ({
      seo: {
        id: node.seo.contentful_id,
        type: DataType.PageSeo,
        data: node.seo,
      },
      date: node.date,
      description: node.description,
      content: node.content,
      slug: node.slug,
      title: node.title,
      subtitle: node.subtitle,
      image: {
        id: node.image.contentful_id,
        type: DataType.Image,
        data: undefined,
      },
    }),
  );

  return list;
};

export const useBlogPostsPartialsData = (): BlogPostPartial[] => {
  const graphqlResult = useStaticQuery(graphql`
    query {
      allContentfulBlogPostPage(sort: { order: DESC, fields: date }) {
        nodes {
          contentful_id
          title
          # subtitle
          date
          content {
            raw
            # references {
            #   contentful_id
            #   title
            #   file {
            #     url
            #   }
            # }
          }
          description
          seo {
            contentful_id
            description
            title
          }
          slug
          image {
            contentful_id
          }
        }
      }
    }
  `);

  return makeList(graphqlResult);
};
