// eslint-disable-next-line @typescript-eslint/ban-types
export const rejectNil = <TData extends object>(data) =>
  Object.entries(data).reduce<TData>((acc, [key, val]) => {
    if (val === undefined || val === null) {
      return acc;
    }

    return { ...acc, [key]: val };
  }, {} as TData);

export const omit = (obj, ...keys) => {
  const keysToRemove = new Set(keys.flat()); // flatten the props, and convert to a Set

  return Object.fromEntries(
    // convert the entries back to object
    Object.entries(obj) // convert the object to entries
      .filter(([key]) => !keysToRemove.has(key)), // remove entries with keys that exist in the Set
  );
};
