import { ContentfulRichText } from '../../types';
import { AboutSectionItemData } from './about-section-item';
import { CarouselSectionData } from './carousel-section';
import { DataEntryBase, DataType } from './common';
import { CtaSectionData } from './cta-section';
import { FormData } from './form';
import { ImageData } from './image';
import { PageSeoData } from './page-seo';
import { RealEstateTypeData } from './real-estate-type';

export type RealEstateDetailsPartial = Partial<
  RealEstateDetailsPageData['data']
>;

export interface RealEstateDetailsTemplateContext {
  data: RealEstateDetailsPageData['data'];
}

export interface RealEstateDetailsParameter {
  name: string;
  value: string;
  icon: ImageData;
}

export enum RealEstateState {
  Available = 'Aktuelle',
  Reserved = 'Reserviert',
  Sold = 'Verkauft',
}

export type RealEstateDetailsPageData = DataEntryBase<
  DataType.RealEstateDetailsPage,
  {
    slug: string;
    seo: PageSeoData;
    date: string;
    state: RealEstateState;
    title: string;
    type: RealEstateTypeData;
    image: ImageData;
    size: number;
    price: number;
    description: ContentfulRichText;
    parameters: {
      title: string;
      items: RealEstateDetailsParameter[];
    }[];
    contactPerson: AboutSectionItemData;
    carousel: CarouselSectionData;
    form: FormData;
    finalEnergyConsumption: number;
    primaryEnergyConsumption: number;
    location: { lon: number; lat: number };
    formattedLocation: string;
    ctaSection?: CtaSectionData;
  }
>;
